import { OwApp } from '../interfaces/domain/ow-app.modal';
import { DownloadCountDto } from '../interfaces/dtos/downloadCount.dto';
import { IAppService } from '../interfaces/services/app-service.interface';
import { DataFetchService } from './data-fetch.service';
import getConfig from 'next/config';
import { ServicesHandler } from './service-handler';

export class AppService implements IAppService {
  private readonly config: any;

  constructor(config: any) {
    this.config = config;
  }

  // ---------------------------------------------------------------------------
  public async getApp(slug: string): Promise<OwApp> {

    const { appConfig } = this.config?.publicRuntimeConfig?.api;
    const app = await DataFetchService.get<OwApp>(
      `${appConfig}/${slug}`
    );

    return app;
  }

  // ---------------------------------------------------------------------------
  public getAppDownloadCount = async (appId: string): Promise<string> => {
    try {
      const { appDownloadCount } = this.config?.publicRuntimeConfig?.api;
      const downloads = await DataFetchService.get<DownloadCountDto>(
        `${appDownloadCount}?appids=[%22${appId}%22]`
      );

      return downloads[appId];
    } catch {
      return null;
    }
  };

  // ---------------------------------------------------------------------------
  public getAppSeoNameByUid = async (appId: string): Promise<string> => {
    try {
      if (!appId) {
        return null;
      }

      const { categoryPageService } = ServicesHandler.getInstance(getConfig());
      const apps = await categoryPageService.getAppsLookup([appId]);

      const tile = apps[appId];
      if (!tile) {
        return null;
      }

      return tile.seoName;
    } catch {
      return null;
    }
  };
}